import { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { doc, getDoc, collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { useShopContext } from '../context/ShopContext';

const useAvailableSlots = (deliveryMode) => {
    const { restaurantId, nextAvailableSlot, setNextAvailableSlot, shopSettings } = useShopContext();
    const [openingHours, setOpeningHours] = useState([]);
    const [closingDays, setClosingDays] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [nextOpeningDate, setNextOpeningDate] = useState(null);
    const preparationTime = Number(shopSettings.general?.preparationTime) || 15;
    const deliveryTime = Number(shopSettings.delivery?.deliveryTime) || 0;
    const maxOrders = Number(shopSettings.general?.maxOrders) || 2;
    const [isLoading, setIsLoading] = useState(true);


    const getDayName = (weekdayNumber) => {
        const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        return dayNames[weekdayNumber - 1];
    };

    useEffect(() => {
        let isMounted = true; 
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const restaurantRef = doc(db, "restaurants", restaurantId);
                const restaurantDocSnap = await getDoc(restaurantRef);
    
                if (restaurantDocSnap.exists()) {
                    const restaurantData = restaurantDocSnap.data();
                    if (isMounted) {
                        setOpeningHours(restaurantData.openingHours || []);
                    }

                } else {
                    if (isMounted) {
                        setOpeningHours([]);
                    }
                }
    
                const settingsRef = doc(db, "restaurants", restaurantId, "settings", "config");
                const settingsDocSnap = await getDoc(settingsRef);
    
                if (settingsDocSnap.exists()) {
                    const settingsData = settingsDocSnap.data();
                    if (isMounted) {
                        setClosingDays(settingsData.shopSettings?.closingDays || []);
                    }
                } else {
                    if (isMounted) {
                        setClosingDays([]);
                    }
                }

            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
            } finally {
                if (isMounted) setIsLoading(false); // Fin du chargement
            }
        };
    
        if (restaurantId) {
            fetchData();
        }
        
        return () => {
            isMounted = false;
        };
    }, [restaurantId]);



    // Fonction pour vérifier si un créneau est fermé
    const isTimeClosed = (date, timeSlot) => {
        const closingDay = closingDays.find(day => 
            DateTime.fromMillis(day.date.seconds * 1000).hasSame(date, 'day')
        );

        if (!closingDay) return false; 

        if (closingDay.isAllDay) return true;


        const closingStart = closingDay.startTime ? DateTime.fromMillis(closingDay.startTime.seconds * 1000) : null;
        const closingEnd = closingDay.endTime ? DateTime.fromMillis(closingDay.endTime.seconds * 1000) : null;

        if (closingStart && closingEnd) {
            if (timeSlot >= closingStart && timeSlot <= closingEnd) {
                return true;
            }
        }
        return false;
    };

    // Fonction pour récupérer les commandes existantes à une heure donnée, excluant les commandes "cancelled"
    const checkSlotAvailability = async (scheduledTimeSlot) => {
        const ordersRef = collection(db, `restaurants/${restaurantId}/orders`);
        
        // Convertir scheduledTimeSlot (DateTime Luxon) en UTC+2
        const scheduledTimeInUTC2 = scheduledTimeSlot.setZone('Europe/Paris');
        
        // Calculer startPreparation en fonction du mode de livraison
        const isPickup = deliveryMode === 'pickup';
        const preparationOffset = isPickup 
            ? preparationTime 
            : preparationTime + deliveryTime;  // Pour delivery, on inclut le deliveryTime
        
        const startPreparationSlot = scheduledTimeInUTC2.minus({ minutes: preparationOffset });
        
        // Convertir ensuite en Timestamp Firestore
        const startPreparationTimestamp = Timestamp.fromDate(startPreparationSlot.toJSDate());
    
        // Requête pour obtenir toutes les commandes non annulées
        const q = query(
            ordersRef,
            where("status", "!=", "cancelled")
        );
    
        const querySnapshot = await getDocs(q);
        
        // Filtrer les commandes pertinentes et vérifier si elles bloquent le créneau
        const relevantOrders = querySnapshot.docs.filter(doc => {
            const orderData = doc.data();
            
            if (orderData.startPreparation) {
                const startPreparationSeconds = orderData.startPreparation.seconds;
                const slotTimeSeconds = startPreparationTimestamp.seconds;
                    
                return (
                    slotTimeSeconds === startPreparationSeconds
                );
            }
            return false;
        });
    
        // Compter les créneaux bloquants
        const relevantOrdersCount = relevantOrders.length;        
        return relevantOrdersCount < maxOrders;
    };
    
    const roundToNextSlot = (time) => {
        const minutes = time.minute;
        const nextSlotMinutes = Math.ceil(minutes / 15) * 15;
        return time.set({ minute: nextSlotMinutes % 60, second: 0 });
    };
    
    const generateSlots = async (startTime, endTime, label, dayDateTime) => {
        const slots = [];
        let slotTime = startTime;
    
        // Obtenir l'heure actuelle pour comparaison
        const currentTime = DateTime.now();
    
        // Ajustement du temps en fonction du mode de livraison (pickup ou delivery)
        const adjustmentTime = deliveryMode === 'delivery'
            ? preparationTime + deliveryTime
            : preparationTime;
    
        // Calculer le premier créneau disponible en fonction du temps d'ajustement
        let firstAvailableSlot = slotTime.plus({ minutes: adjustmentTime });
    
        // Si c'est aujourd'hui, on s'assure de ne pas proposer des créneaux avant l'heure actuelle ajustée
        if (label === "Today") {
            const minimumSlotTime = currentTime.plus({ minutes: adjustmentTime });
            if (firstAvailableSlot < minimumSlotTime) {
                firstAvailableSlot = minimumSlotTime;
            }
        }
    
        // Arrondir le premier créneau au prochain intervalle de 15 minutes
        firstAvailableSlot = roundToNextSlot(firstAvailableSlot);
    
        // Initialiser `slotTime` avec le premier créneau arrondi
        slotTime = firstAvailableSlot;
    
        // Boucle sur chaque créneau de 15 minutes jusqu'à `endTime`
        while (slotTime <= endTime) {
            // Si le créneau est dans le passé, on passe au suivant
            if (slotTime < currentTime.plus({ minutes: adjustmentTime })) {
                slotTime = slotTime.plus({ minutes: 15 });
                continue;
            }
    
            if (slotTime > endTime) break;
    
            // Vérifier si le créneau est fermé
            if (!isTimeClosed(dayDateTime, slotTime)) {
                const isAvailable = await checkSlotAvailability(slotTime);
                if (isAvailable) {
                    slots.push({ time: slotTime, label });
                }
            }
    
            // Passer au créneau suivant de 15 minutes
            slotTime = slotTime.plus({ minutes: 15 });
        }
    
        return slots;
    };
    
    
    

    // Fonction pour calculer la prochaine date d'ouverture
    const findNextOpeningDate = () => {
        const now = DateTime.local();
        
        // Parcourir les 7 prochains jours pour trouver une date d'ouverture
        for (let i = 0; i < 7; i++) {
            const futureDate = now.plus({ days: i });
            const futureDayName = getDayName(futureDate.weekday);
    
            // Vérifier si le restaurant est ouvert ce jour-là
            const openingForFutureDay = openingHours.find((hours) => hours.day === futureDayName);
            if (openingForFutureDay && openingForFutureDay.slots.length > 0) {
                
                // Vérifier si le restaurant n'est pas fermé toute la journée
                const closingDay = closingDays.find(day => 
                    DateTime.fromMillis(day.date.seconds * 1000).hasSame(futureDate, 'day')
                );
    
                if (!closingDay || !closingDay.isAllDay) {
                    // Si le restaurant est ouvert et pas fermé, retourner cette date
                    return futureDate.startOf('day');
                }
            }
        }
    
        // Si aucune date d'ouverture trouvée dans les 7 prochains jours, renvoyer null
        return null;
    };
    

    useEffect(() => {
        if (openingHours.length > 0 && closingDays.length >= 0) {
            let isMounted = true;
            const now = DateTime.local();
            const todayName = getDayName(now.weekday); 
            const tomorrowName = getDayName(now.plus({ days: 1 }).weekday);

            const calculateNextAvailableSlot = async () => {
                setIsLoading(true); // Déclenchement du chargement lors de la mise à jour du deliveryMode
                const todayOpening = openingHours.find((hours) => hours.day === todayName); 
                const tomorrowOpening = openingHours.find((hours) => hours.day === tomorrowName); 
                let allSlots = [];

                const addSlotsForDay = async (dayOpening, dayDateTime, label) => {
                    if (!dayOpening) return;
                    
                    for (const timeSlot of dayOpening.slots) {
                        const openingTime = DateTime.fromISO(dayDateTime.toISODate() + 'T' + timeSlot.start);
                        const closingTime = DateTime.fromISO(dayDateTime.toISODate() + 'T' + timeSlot.end);
                        const slots = await generateSlots(openingTime, closingTime, label, dayDateTime);
                        if (isMounted) allSlots = allSlots.concat(slots);
                    }
                };

                await addSlotsForDay(todayOpening, now, "Today");
                await addSlotsForDay(tomorrowOpening, now.plus({ days: 1 }), "Tomorrow");

                if (isMounted) {
                    setNextAvailableSlot(allSlots.length > 0 ? allSlots[0].time : null);
                    setAvailableSlots(allSlots);
                    
                    if (allSlots.length === 0) {
                        const nextOpening = findNextOpeningDate();
                        setNextOpeningDate(nextOpening);
                    }
                }

                setIsLoading(false); // Fin du chargement
            };

            calculateNextAvailableSlot();

            return () => {
                isMounted = false;
            };
        }
    }, [openingHours, closingDays, preparationTime, deliveryTime, deliveryMode]);


    return { nextAvailableSlot, availableSlots, nextOpeningDate, isLoading };
};

export default useAvailableSlots;
