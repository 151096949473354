import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useShopContext } from "../../context/ShopContext";
import CartContent from "../cart/CartContent";
import DeliveryModeSwitcher from "./DeliveryModeSwitcher";
import AddressAutoComplete from "./AddressAutoComplete";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import StripePaymentForm from "../StripePaymentForm";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import {
  Textarea,
  Box,
  Flex,
  Stack,
  Button,
  Divider,
  Link,
  Skeleton,
  Text,
  Input,
} from "@chakra-ui/react";
import { User, Store, CreditCard, Coins } from "lucide-react";
import { useAuth } from "../../context/AuthContext";
import Map from "./Map";
import Coupon from "./Coupon";
import useCoupons from "../../../hooks/useCoupons";
import SlotsSelection from "./SlotsSelection";
import { DateTime } from "luxon"; // Pour une manipulation plus simple des dates/heures
import useAvailableSlots from "../../hooks/useAvailableSlots";
import useCheckout from "../../hooks/useCheckout";

const Checkout = ({ restaurantId, stripePromise }) => {
  const { currentUser, toggleUserDetailsModal } = useAuth();
  const {
    cartItems,
    styles,
    shopSettings,
    formData,
    setFormData,
    submitOrder,
    totalWithTip,
    deliveryMode,
    setDeliveryMode,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    isDeliveryZoneValid,
    calculateSubtotal,
    clearCart,
    nextAvailableSlot,
    scheduledSlotDate,
    isAddressSelected,
    setScheduledSlotDate,
    formatPrice,
    totalAfterDiscountValue,
    setTotalAfterDiscountValue,
    couponDetails,
    isPlanningTime,
    setIsPlanningTime,
    isFormValid,
    setIsAddressSelected,
  } = useShopContext();

  const { isCheckoutDisabledCheckout } = useCheckout();

  const {
    couponCode,
    discountAmount,
    isCouponValid,
    handleApplyCoupon,
    logCouponUsage,
  } = useCoupons(restaurantId);

  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const [stripeFieldsComplete, setStripeFieldsComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [deliveryNote, setDeliveryNote] = useState("");
  const [scheduleModalIsOpen, setScheduleModalIsOpen] = useState(false);
  const [isNextAvailableSelected, setIsNextAvailableSelected] = useState(true);
  const [manualSelectionMade, setManualSelectionMade] = useState(false);
  const { isLoading } = useAvailableSlots(deliveryMode);
  const openScheduleModal = () => setScheduleModalIsOpen(true);

  useEffect(() => {
    // Charge l'adresse du localStorage si elle est disponible et définissez-la dans formData
    const savedAddress = localStorage.getItem("userAddress");
    if (savedAddress) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        customerDetails: {
          ...prevFormData.customerDetails,
          address: savedAddress, // Définit l'adresse dans customerDetails
        },
      }));
      setIsAddressSelected(true); // Met à jour isAddressSelected pour l'adresse initiale
    }
  }, [setFormData, setIsAddressSelected]);

  useEffect(() => {
    if (currentUser) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        customerDetails: {
          ...prevFormData.customerDetails,
          firstName:
            prevFormData.customerDetails.firstName ||
            currentUser.firstName ||
            "",
          lastName:
            prevFormData.customerDetails.lastName || currentUser.lastName || "",
          email: prevFormData.customerDetails.email || currentUser.email || "",
          phone: prevFormData.customerDetails.phone || currentUser.phone || "",
          address:
            prevFormData.customerDetails.address || currentUser.address || "",
          userId: prevFormData.customerDetails.userId || currentUser.uid || "",
        },
      }));
    }
  }, [currentUser, setFormData]);

  const closeScheduleModal = () => {
    if (!manualSelectionMade) {
      setSelectedSlot(nextAvailableSlot);
      setScheduledSlotDate(nextAvailableSlot.toMillis()); // <-- Mettre à jour scheduledSlotDate en tant que timestamp
      setIsNextAvailableSelected(true);
      setIsPlanningTime(false);
    }
    setScheduleModalIsOpen(false);
  };

  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);

  useEffect(() => {
    if (shopSettings && shopSettings.takeAway && shopSettings.delivery) {
      if (!isSettingsLoaded) {
        // Check if deliveryMode is stored in localStorage
        const savedDeliveryMode = localStorage.getItem("deliveryMode");

        // Validate the stored deliveryMode with the current shopSettings
        if (
          savedDeliveryMode === "delivery" &&
          shopSettings.delivery.activation === false
        ) {
          // If "delivery" is saved but delivery is deactivated, switch to "pickup"
          setDeliveryMode("pickup");
          localStorage.setItem("deliveryMode", "pickup");
        } else if (
          savedDeliveryMode === "pickup" &&
          shopSettings.takeAway.activation === false
        ) {
          // If "pickup" is saved but pickup is deactivated, switch to "delivery"
          setDeliveryMode("delivery");
          localStorage.setItem("deliveryMode", "delivery");
        } else if (savedDeliveryMode) {
          // If the saved deliveryMode is valid, use it
          setDeliveryMode(savedDeliveryMode);
        } else {
          // No saved deliveryMode, fallback to shopSettings logic
          if (
            shopSettings.takeAway.activation === false &&
            shopSettings.delivery.activation === true
          ) {
            setDeliveryMode("delivery");
            localStorage.setItem("deliveryMode", "delivery");
          } else if (shopSettings.takeAway.activation === true) {
            setDeliveryMode("pickup");
            localStorage.setItem("deliveryMode", "pickup");
          }
        }

        // Mark settings as loaded
        setIsSettingsLoaded(true);
      }
    }
  }, [shopSettings, setDeliveryMode, isSettingsLoaded]);

  useEffect(() => {
    if (!isPlanningTime) {
      setSelectedSlot(nextAvailableSlot);
      setScheduledSlotDate(nextAvailableSlot);
      setIsNextAvailableSelected(true);
      setManualSelectionMade(false);
    }
  }, [isPlanningTime, nextAvailableSlot]);

  const handleSlotSelection = (slot) => {
    let selectedSlotTime = slot || nextAvailableSlot; // Reste un objet Luxon DateTime

    let dayLabel = "";
    const today = DateTime.local().startOf("day");
    const tomorrow = today.plus({ days: 1 }).startOf("day");

    // Formater l'heure du créneau sélectionné
    const formattedSlotTime = selectedSlotTime.toFormat("HH:mm"); // Formattage en HH:mm avec Luxon

    // Vérifier si le créneau est pour "Today" ou "Tomorrow"
    if (selectedSlotTime.hasSame(today, "day")) {
      dayLabel = "Today";
    } else if (selectedSlotTime.hasSame(tomorrow, "day")) {
      dayLabel = "Tomorrow";
    }

    // Mettre à jour selectedSlot pour inclure le jour et l'heure
    setSelectedSlot(`${dayLabel} ${formattedSlotTime}`);

    // Enregistrer `scheduledSlotDate` comme un objet Luxon `DateTime`
    setScheduledSlotDate(selectedSlotTime);

    setIsNextAvailableSelected(!slot); // Si pas de slot, alors next available est sélectionné
    setManualSelectionMade(!!slot); // Si un slot manuel est sélectionné
    setIsPlanningTime(true);

    // Fermer la modal après la sélection
    setScheduleModalIsOpen(false);
  };

  const checkoutDisabled = isCheckoutDisabledCheckout();

  const navigate = useNavigate();
  useEffect(() => {
    if (cartItems.length === 0) {
      navigate(`/${restaurantId}/shop`);
    }
  }, [cartItems, navigate, restaurantId]);

  const addressInputStyle =
    deliveryMode === "delivery" ? {} : { display: "none" };

  const handleCardDetailsChange = (event) => {
    setStripeFieldsComplete(event.complete);
    setErrorMessage(event.error ? event.error.message : ""); // Mettre à jour le message d'erreur en temps réel
  };

  useEffect(() => {
    const updatedTotal =
      parseFloat(totalWithTip().replace(",", ".")) -
        parseFloat(discountAmount) || 0;
    setTotalAfterDiscountValue(formatPrice(updatedTotal));
  }, [discountAmount, totalWithTip, couponCode]); // Add relevant dependencies here

  const stripe = useStripe();
  const elements = useElements();
  const functions = getFunctions();
  const createPaymentIntent = httpsCallable(functions, "createPaymentIntent");

  // Trouver l'objet qui correspond à "online" dans le tableau paymentMethods
  const onlinePaymentMethod = shopSettings?.paymentMethods?.find(
    (method) => method.id === "online"
  );
  const stripeTestMode = onlinePaymentMethod?.stripeTestMode || false;

  const handleSubmitOrder = async (orderData) => {
    const updatedOrderData = {
      ...formData,
      cartItems,
      subTotal: calculateSubtotal(),
      total: totalAfterDiscountValue,
      paymentMethod: selectedPaymentMethod,
      scheduledTime: scheduledSlotDate || nextAvailableSlot.toMillis(),
      deliveryNote: deliveryMode === "delivery" ? deliveryNote : "",
      couponCode: couponCode || null,
      discount: discountAmount || 0,
    };

    try {
      const orderResult = await submitOrder(updatedOrderData, "unpaid");
      console.log("Order submitted:", orderResult);
      return orderResult; // Retourne l'objet avec orderId pour l'utiliser plus tard
    } catch (error) {
      console.error("Error during order submission:", error);
      throw error; // Relance l'erreur pour la gestion dans handlePayClick
    }
  };

  const handlePayClick = async () => {
    setIsSubmittingPayment(true);

    if (couponDetails) {
      await handleApplyCoupon();
      if (!isCouponValid) {
        toast.error("The coupon is no longer valid.");
        setIsSubmittingPayment(false);
        return;
      }
    }

    const totalWithTipValue = parseFloat(totalWithTip().replace(",", ".")) || 0;
    const discountValue = parseFloat(discountAmount) || 0;
    const totalAfterDiscountValue = totalWithTipValue - discountValue;

    if (isNaN(totalAfterDiscountValue)) {
      toast.error("An error occurred calculating the total. Please try again.");
      setIsSubmittingPayment(false);
      return;
    }

    const orderData = {
      ...formData,
      cartItems,
      subTotal: calculateSubtotal(),
      total: totalAfterDiscountValue.toFixed(2).replace(".", ","),
      paymentMethod: selectedPaymentMethod,
      scheduledTime: scheduledSlotDate || nextAvailableSlot.toMillis(),
      deliveryNote: deliveryMode === "delivery" ? deliveryNote : "",
      couponCode: couponCode || null,
      discount: discountAmount || 0,
    };

    try {
      let orderResult;
      if (selectedPaymentMethod === "till") {
        orderResult = await handleSubmitOrder(orderData);
      } else if (selectedPaymentMethod === "online") {
        orderResult = await handleStripePayment(orderData);
      } else {
        throw new Error("Payment method undefined");
      }

      // Vérification de l'existence de orderId avant de l'utiliser
      if (orderResult && orderResult.orderId) {
        if (couponCode && isCouponValid && couponDetails) {
          await logCouponUsage(
            couponDetails.id,
            currentUser.uid,
            orderResult.orderId
          );
        } else {
          console.error(
            "Coupon details are missing, order ID is missing or invalid for coupon logging, or isCouponValid is false."
          );
        }

        navigate(`/account/orders/${restaurantId}/${orderResult.orderId}`);
      } else {
        console.error("Order ID is missing in orderResult.");
        throw new Error("Order creation failed, no order ID returned.");
      }
    } catch (error) {
      console.error(error);
      setIsSubmittingPayment(false);
      toast.error("An error occurred during the payment process.");
    }
  };

  const handleStripePayment = async (orderData) => {
    if (!stripeFieldsComplete) {
      setErrorMessage("Please fill in all required card information.");
      setIsSubmittingPayment(false);
      return;
    }

    // Convert `totalAfterDiscountValue` to a numeric format (Stripe expects the amount in cents)
    const amountToCharge = parseFloat(totalWithTip().replace(",", ".")) * 100; // Amount in cents for Stripe

    // Fetch the clientSecret from Stripe to proceed with the payment
    const clientSecret = await fetchClientSecret(amountToCharge);

    if (clientSecret) {
      const { paymentMethodId, paymentInfo, error } = await submitStripePayment(
        clientSecret
      );

      if (error) {
        toast.error("Payment failed: " + error, { autoClose: false });
        setIsSubmittingPayment(false);
      } else {
        // Add payment information and status to orderData
        orderData.paymentMethodId = paymentMethodId;
        orderData.paymentStatus = "paid";
        orderData.paymentMethod = "online";

        // Only include paymentInfo if it's not null
        if (paymentInfo) {
          orderData.paymentInfo = paymentInfo; // Include card details if available
        }

        // Submit the order with the updated orderData
        const orderResult = await handleSubmitOrder(orderData, "paid");
        return orderResult;
      }
    } else {
      setIsSubmittingPayment(false);
    }
  };

  // Réinsertion de la fonction fetchClientSecret
  const fetchClientSecret = async (amountToCharge) => {
    try {
      const { data } = await createPaymentIntent({
        amount: amountToCharge,
        restaurantId,
        stripeTestMode, // Passez le mode Test ou Live
      });

      return data.clientSecret;
    } catch (error) {
      console.error("Erreur lors de l'obtention du clientSecret:", error);
      toast.error("Problem fetching payment information. Please try again.");
      setIsSubmittingPayment(false);
      return null; // Ajoutez un return null en cas d'erreur pour éviter de continuer
    }
  };

  const submitStripePayment = async (clientSecret) => {
    if (!stripe || !elements) {
      const errorMsg = "Stripe.js hasn't loaded yet.";
      setErrorMessage(errorMsg);
      toast.error(errorMsg); // Display an error toast
      return { error: errorMsg };
    }

    const cardElement = elements.getElement(CardElement);

    // Confirm the card payment via Stripe
    const { error, paymentIntent, paymentMethod } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${formData.customerDetails.firstName} ${formData.customerDetails.lastName}`,
            email: formData.customerDetails.email,
          },
        },
      });

    if (error) {
      console.error(`Payment failed: ${error.message}`);
      setErrorMessage(error.message);
      toast.error(`Payment failed: ${error.message}`, { autoClose: false });
      return { error: error.message };
    }

    // Obtenir les détails de la carte directement après la confirmation de paiement
    let paymentMethodDetails = paymentMethod?.card;

    // Si les détails de la carte ne sont pas présents dans paymentMethod, essayez dans paymentIntent
    if (!paymentMethodDetails && paymentIntent?.charges?.data?.length > 0) {
      const charge = paymentIntent.charges.data[0];
      paymentMethodDetails = charge.payment_method_details?.card; // Get card details from the charge
    }

    // Si les informations de la carte ne sont toujours pas disponibles
    if (!paymentMethodDetails) {
      console.warn("Card details not found. Skipping paymentInfo.");
      return { paymentMethodId: paymentIntent.id, paymentInfo: null }; // Return paymentInfo as null
    }

    // Récupérer les informations utiles comme la marque et les 4 derniers chiffres
    const paymentInfo = {
      brand: paymentMethodDetails.brand || "Unknown", // Fallback to "Unknown" if brand is missing
      last4: paymentMethodDetails.last4 || "****", // Fallback to "****" if the last4 is missing
      paymentMethodId: paymentIntent.id, // Payment Intent ID
    };

    return { paymentMethodId: paymentIntent.id, paymentInfo };
  };

  const [isMapLoading, setIsMapLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsMapLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        theme="colored"
        position="top-center"
      />
      <Flex
        as="main"
        className={styles.wrapper}
        style={{ background: "var(--grey100)" }}
        flex={1}>
        <Flex
          direction={{ desktop: "row", mobile: "column" }}
          maxW="1100px"
          gap={{ desktop: 8, mobile: 0 }}
          p={{ desktop: "48px 0", mobile: "0" }}
          className={`${styles.container} ${styles.checkout}`}>
          
          
          
          <Flex
            direction="column"
            width={{ desktop: "60%", mobile: "100%" }}
            borderRadius={{ desktop: "8px", mobile: "0" }}
            overflow={"hidden"}>
            <Flex direction="column" background="white" p={8} gap={4}>
              <Flex direction="row" justifyContent="space-between">
                <h3>
                  {deliveryMode === "delivery"
                    ? "Delivery details"
                    : "Pickup details"}
                </h3>
                <DeliveryModeSwitcher />
              </Flex>

              {deliveryMode === "pickup" && (
                <>
                  <Flex direction="column" gap={4}>
                    {isMapLoading ? (
                      <Skeleton height="250px" />
                    ) : (
                      <Map
                        address={shopSettings.contact.address}
                        title={restaurantId}
                      />
                    )}
                    <Flex direction="row" justifyContent="space-between">
                      <Flex direction="row" align="center" gap={4}>
                        <Store size={24} />
                        <Stack gap={0}>
                          <Text
                            style={{ textTransform: "capitalize" }}
                            fontWeight="600"
                            color={"var(--grey700)"}>
                            {restaurantId}
                          </Text>
                          <Text
                            style={{
                              fontSize: "14px",
                              color: "var(--grey500)",
                            }}>
                            {shopSettings.contact.address}
                          </Text>
                        </Stack>
                      </Flex>
                      <Button
                        size="md"
                        variant={"outline"}
                        as={Link}
                        href={`tel:${shopSettings.contact.phoneNumber}`}>
                        Call restaurant
                      </Button>
                    </Flex>
                  </Flex>
                </>
              )}

              {deliveryMode === "delivery" && (
                <>
                  <Flex className="field" style={addressInputStyle}>
                    <AddressAutoComplete />
                  </Flex>

                  <Flex direction="column" background="white" gap={2}>
                    <h5>Add a note for the delivery</h5>
                    <div className="timeOptions">
                      <Textarea
                        className="option"
                        value={deliveryNote}
                        onChange={(e) => setDeliveryNote(e.target.value)}
                        placeholder="Eg: Floor number or company name"
                        isDisabled={deliveryMode !== "delivery"}
                      />
                    </div>
                  </Flex>
                </>
              )}
            </Flex>

            <Divider height="2px" />

            <Flex direction="column" background="white" p={8} gap={4}>
              <h3>
                Select a {deliveryMode === "delivery" ? "delivery" : "pickup"}{" "}
                time
              </h3>
              <Flex direction="column" gap={2} className="timeOptions">
                <SlotsSelection
                  restaurantId={restaurantId}
                  isPlanningTime={isPlanningTime}
                  setIsPlanningTime={setIsPlanningTime}
                  deliveryMode={deliveryMode}
                  handleSlotSelection={handleSlotSelection}
                  selectedSlot={selectedSlot}
                  isNextAvailableSelected={isNextAvailableSelected}
                  scheduleModalIsOpen={scheduleModalIsOpen}
                  openScheduleModal={openScheduleModal}
                  closeScheduleModal={closeScheduleModal}
                />
              </Flex>
            </Flex>

            <Divider height="2px" />

            <Flex direction="column" background="white" p={8} gap={4}>
              <h3>Personal details</h3>
              <Flex
                direction="row"
                align="center"
                justifyContent="space-between"
                p="16px"
                borderRadius={8}
                border="2px solid"
                borderColor={isFormValid() ? "#121212" : "var(--borders)"}>
                <Flex direction="row" align="center" gap={4}>
                  <User size={24} />
                  <Stack gap={0}>
                    <Box>
                      {formData.customerDetails.firstName}{" "}
                      {formData.customerDetails.lastName}
                    </Box>
                    <Box style={{ fontSize: "14px", color: "var(--grey500)" }}>
  {currentUser?.phone && typeof currentUser?.phone === "string" ? (
    <span>+{currentUser.phone}</span>
  ) : (
    <Text color={"red"}>Please insert a phone number</Text>
  )}
</Box>


                  </Stack>
                </Flex>
                <Button
                  size="sm"
                  className="btn secondary sm"
                  onClick={toggleUserDetailsModal}>
                  Modify
                </Button>
              </Flex>
            </Flex>

            <Divider height="2px" />

            <Flex direction="column" background="white" p={8} gap={4}>
              <h3>How would you like to pay?</h3>
              <Flex
                direction={{ desktop: "row", mobile: "column" }}
                className={styles.paymentMethods}
                gap={2}>
                {shopSettings.paymentMethods &&
                Object.values(shopSettings.paymentMethods).filter((method) => {
                  // Désactiver l'option "online" si stripePromise est null, même si elle est activée
                  if (method.name === "online" && !stripePromise) {
                    return false;
                  }
                  return method.activation;
                }).length > 0 ? (
                  Object.values(shopSettings.paymentMethods)
                    .filter((method) => {
                      // Même filtre pour désactiver "online" si stripePromise est null
                      if (method.name === "online" && !stripePromise) {
                        return false;
                      }
                      return method.activation;
                    })
                    .sort((a, b) => a.order - b.order)
                    .map((method, index) => (
                      <Flex
                        className="paymentMethodOption"
                        flex={1}
                        key={index}>
                        <Input
                          hidden
                          type="radio"
                          id={`payment-method-${method.name}`}
                          name="paymentMethod"
                          value={method.name}
                          checked={selectedPaymentMethod === method.name}
                          onChange={(e) =>
                            setSelectedPaymentMethod(e.target.value)
                          }
                        />
                        <label
                          htmlFor={`payment-method-${method.name}`}
                          className={styles.option}>
                          <Flex gap={3}>
                            {method.name === "till" ? (
                              <Coins />
                            ) : (
                              <CreditCard />
                            )}
                            <Text fontWeight={600} fontSize={"15px"}>
                              {method.label}
                            </Text>
                          </Flex>
                        </label>
                      </Flex>
                    ))
                ) : (
                  <p>No payment methods available</p>
                )}
              </Flex>

              {selectedPaymentMethod === "online" &&
                onlinePaymentMethod?.activation && (
                  <StripePaymentForm
                    onStripePayment={handlePayClick}
                    stripe={stripe}
                    elements={elements}
                    onChange={handleCardDetailsChange}
                  />
                )}

              {errorMessage && (
                <Box color="red" fontSize="sm">
                  {errorMessage}
                </Box>
              )}
            </Flex>
          </Flex>

          <Flex direction="column" style={{ flex: "1" }}>
            <Flex direction="column" className={styles.checkoutSticky}>
              <div className={styles.section}>
                <div className={styles.cartContent}>
                  <Flex direction="row" justifyContent="space-between">
                    <h3>Order summary</h3>
                    <Button
                      variant={"outline"}
                      size={"sm"}
                      onClick={clearCart}
                      fontSize={"12px !important"}>
                      Clear cart
                    </Button>
                  </Flex>
                  <CartContent />
                </div>
              </div>
              <Coupon
                restaurantId={restaurantId}
                calculateSubtotal={calculateSubtotal}
                totalWithTip={totalWithTip}
                discountAmount={discountAmount}
              />

              <Box p={{ desktop: "0", mobile: "0 16px 16px" }}>
                <Button
                  width="100%"
                  gap={1}
                  as="button"
                  size="lg"
                  type="button"
                  isLoading={isSubmittingPayment}
                  isDisabled={
                    (selectedPaymentMethod === "online" &&
                      !stripeFieldsComplete) ||
                    checkoutDisabled ||
                    !isDeliveryZoneValid ||
                    errorMessage ||
                    !nextAvailableSlot ||
                    isLoading ||
                    (deliveryMode === "delivery" && !isAddressSelected)
                  }
                  onClick={handlePayClick}
                  className="primary">
                  Place order<span>{totalAfterDiscountValue}€</span>
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Checkout;
